import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import LoaderComponent from '../Loader';
import GridItem from "../Grid/GridItem.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import CalenderIcon from "@material-ui/icons/DateRange";
import CustomInput from "../CustomInput/CustomInput.jsx";
import DatePicker from "../CustomInput/DatePicker";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";

import advancedSearchModalStyle from "../../../assets/jss/user/advancedSearchModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const AddRuleModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;
        const { searchFormData } = this.props;
        
        this.state = {
            searchFormData: searchFormData,
            fileName: searchFormData.fileName,
            searchIn: searchFormData.searchIn,
            attachmentContains: searchFormData.attachmentContains,
            from: searchFormData.from,
            to: searchFormData.to,
            subject: searchFormData.subject,
            destination: searchFormData.destination,
            expired: searchFormData.expired,
            notExpired: searchFormData.notExpired,
            downloaded: searchFormData.downloaded,
            notDownloaded: searchFormData.notDownloaded,
            changedLinks: searchFormData.changedLinks,
            startDate: searchFormData.startDate,
            endDate: searchFormData.endDate,
            expiryStartDate: searchFormData.expiryStartDate,
            expiryEndDate: searchFormData.expiryEndDate,
            saving: false,
            response: null,
            cancelToken: null,
            showError: false,
            errorMessage: "",
            validation: {
                startDate: "",
                endDate: "",
                expiryStartDate: "",
                expiryEndDate: "",
                isValid: false
            },
            requireLowerletter: false,
            requireUpperletter: false,
            requireNumber: false,
            requireSymbol: false,
            requireLength: false,
        };

        this.submitSearch = this.submitSearch.bind(this);
        this.searchInTypes = this.searchInTypes();
    }
    searchInTypes(){
        return [
            {
                'id': 'web',
                'label': 'Web'
            },
            {
                'id': 'email',
                'label': 'Email'
            },
            {
                'id': 'docs',
                'label': 'My Docs'
            },
            {
                'id': 'link-manager',
                'label': 'Link Manager'
            },
        ]
    }
    validateForm(){
        let validation = {
            startDate: "success",
            endDate: "success",
            expiryStartDate: "success",
            expiryEndDate: "success",
            isValid: true
        };
        const { startDate, endDate, expiryStartDate, expiryEndDate } = this.state;
        if(startDate.length <= 0 && endDate.length > 0){
            validation.startDate = "error";
            validation.isValid = false;
        }
        if(expiryStartDate.length <= 0 && expiryEndDate.length > 0){
            validation.expiryStartDate = "error";
            validation.isValid = false;
        }

        this.setState({validation: validation});
        return validation.isValid;
    }
    submitSearch(){
        const isValid = this.validateForm();
        if(!isValid || this.state.loading){
            return;
        }

        const { fileName, searchIn, attachmentContains, from, to, subject, destination, expired, notExpired, downloaded, notDownloaded, startDate, endDate, expiryStartDate, expiryEndDate } = this.state;

        const searchData = {
            fileName: fileName,
            searchIn: searchIn,
            attachmentContains: attachmentContains,
            from: from,
            to: to,
            subject: subject,
            destination: destination,
            expired: expired,
            notExpired: notExpired,
            downloaded: downloaded,
            notDownloaded: notDownloaded,
            startDate: startDate,
            endDate: endDate,
            expiryStartDate: expiryStartDate,
            expiryEndDate: expiryEndDate
        };

        this.props.onSuccess(searchData);
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<GridItem key={key} className={"passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </GridItem>);
                key++;
            }
            return errorMessages;
        }
        return <GridItem className={"passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </GridItem>;
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        this.setState(state);
    }
    handleCheckbox(e, name){
        this.setState({
            [name]: e.target.checked
        });
    }
    handleDateChange(date, name) {
        try{
            let parsedDate = new Date(date);
            if(parsedDate === "Invalid Date"){
                return;
            }
            this.setState({
                [name]: parsedDate.toISOString()
           });
        }catch(e){ console.log(e); }        
    }
    renderForm(){
        const { fileName, saving, searchIn, attachmentContains, from, to, subject, destination, 
            expired, notExpired, downloaded, notDownloaded, changedLinks,
            startDate, endDate, expiryStartDate, expiryEndDate, validation } = this.state;
        const { classes } = this.props;
        const location = this.history.location;
        const pathname = location.pathname;
        
        return(
            <div className={classes.addUserContainer}>
                <div className={classes.addUserForm}>
                    <div>
                        <CustomInput
                            id="input-fileName"
                            labelText="File Name"
                            inputProps={{
                                onChange: (e) => this.handleChange(e,'fileName'),
                                value: fileName,
                                name: "fileName",
                                type: "text",
                            }}
                            color="custom"
                            formControlProps={{
                                fullWidth: true,
                                className: "custom-input"
                            }}
                        />
                        {
                            searchIn === 'link-manager' || pathname === '/user/links' ?
                                <>
                                    <CustomInput
                                        id="input-attachmentContains"
                                        labelText="Attachment Contains"
                                        inputProps={{
                                            onChange: (e) => this.handleChange(e,'attachmentContains'),
                                            value: attachmentContains,
                                            name: "attachmentContains",
                                            type: "text",
                                        }}
                                        color="custom"
                                        formControlProps={{
                                            fullWidth: true,
                                            className: "custom-input"
                                        }}
                                    />
                                    <CustomInput
                                        id="input-from"
                                        labelText="From"
                                        inputProps={{
                                            onChange: (e) => this.handleChange(e,'from'),
                                            value: from,
                                            name: "from",
                                            type: "email",
                                        }}
                                        color="custom"
                                        formControlProps={{
                                            fullWidth: true,
                                            className: "custom-input"
                                        }}
                                    />
                                    <CustomInput
                                        id="input-to"
                                        labelText="To"
                                        inputProps={{
                                            onChange: (e) => this.handleChange(e,'to'),
                                            value: to,
                                            name: "to",
                                            type: "email",
                                        }}
                                        color="custom"
                                        formControlProps={{
                                            fullWidth: true,
                                            className: "custom-input"
                                        }}
                                    />
                                    <CustomInput
                                        id="input-subject"
                                        labelText="Subject"
                                        inputProps={{
                                            onChange: (e) => this.handleChange(e,'subject'),
                                            value: subject,
                                            name: "subject",
                                            type: "text",
                                        }}
                                        color="custom"
                                        formControlProps={{
                                            fullWidth: true,
                                            className: "custom-input"
                                        }}
                                    />
                                    <CustomInput
                                        id="input-destination"
                                        labelText="Destination Link Name"
                                        inputProps={{
                                            onChange: (e) => this.handleChange(e,'destination'),
                                            value: destination,
                                            name: "destination",
                                            type: "text",
                                        }}
                                        color="custom"
                                        formControlProps={{
                                            fullWidth: true,
                                            className: "custom-input"
                                        }}
                                    />
                                    <div className={classes.alignLeft}>
                                        <FormControlLabel
                                            classes={{label: classes.label}}
                                            control={
                                                <Checkbox                                                
                                                    tabIndex={-1}
                                                    onClick={(e) => this.handleCheckbox(e, "expired")}
                                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    classes={{checked: classes.checked, root: classes.checkRoot}}
                                                    checked={(expired ? true : false)}
                                                />
                                            }
                                            label="Expired"
                                        />
                                        <FormControlLabel
                                            classes={{label: classes.label}}
                                            control={
                                                <Checkbox                                                
                                                    tabIndex={-1}
                                                    onClick={(e) => this.handleCheckbox(e, "notExpired")}
                                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    classes={{checked: classes.checked, root: classes.checkRoot}}
                                                    checked={(notExpired ? true : false)}
                                                />
                                            }
                                            label="Not Expired"
                                        />
                                        <FormControlLabel
                                            classes={{label: classes.label}}
                                            control={
                                                <Checkbox                                                
                                                    tabIndex={-1}
                                                    onClick={(e) => this.handleCheckbox(e, "downloaded")}
                                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    classes={{checked: classes.checked, root: classes.checkRoot}}
                                                    checked={(downloaded ? true : false)}
                                                />
                                            }
                                            label="Downloaded"
                                        />
                                        <FormControlLabel
                                            classes={{label: classes.label}}
                                            control={
                                                <Checkbox                                                
                                                    tabIndex={-1}
                                                    onClick={(e) => this.handleCheckbox(e, "notDownloaded")}
                                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    classes={{checked: classes.checked, root: classes.checkRoot}}
                                                    checked={(notDownloaded ? true : false)}
                                                />
                                            }
                                            label="Not Downloaded"
                                        />
                                        <FormControlLabel
                                            classes={{label: classes.label}}
                                            control={
                                                <Checkbox                                                
                                                    tabIndex={-1}
                                                    onClick={(e) => this.handleCheckbox(e, "changedLinks")}
                                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    classes={{checked: classes.checked, root: classes.checkRoot}}
                                                    checked={(changedLinks ? true : false)}
                                                />
                                            }
                                            label="Recently Changed Links"
                                        />
                                    </div>
                                </>
                            :
                                <></>
                        }
                        <div className='date-fields'>
                            <DatePicker
                                success={validation.startDate === "success" ? "true" : ""}
                                error={validation.startDate === "error"}
                                labelText={null}
                                id="input-startDate"
                                value={startDate}
                                onChange={(date) => this.handleDateChange(date, 'startDate')}
                                inputProps={{
                                    placeholder:"Start Date",
                                    startAdornment: (
                                        <InputAdornment position="start" className={classes.inputAdornment} >
                                            <CalenderIcon className={classes.inputAdornmentIcon}/>
                                        </InputAdornment>
                                    ),
                                }}
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.customFormControlClasses
                                }}
                            />
                            <DatePicker
                                success={validation.endDate === "success" ? "true" : ""}
                                error={validation.endDate === "error"}
                                labelText={null}
                                id="input-endDate"
                                value={endDate}
                                onChange={(date) => this.handleDateChange(date, 'endDate')}
                                inputProps={{
                                    placeholder:"End Date",
                                    startAdornment: (
                                        <InputAdornment position="start" className={classes.inputAdornment} >
                                            <CalenderIcon className={classes.inputAdornmentIcon}/>
                                        </InputAdornment>
                                    ),
                                }}
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.customFormControlClasses
                                }}
                            />
                        </div>
                        {
                            searchIn === 'link-manager' || pathname === '/user/links' ?
                                <>
                                    <div className='date-fields'>
                                        <DatePicker
                                            success={validation.expiryStartDate === "success" ? "true" : ""}
                                            error={validation.expiryStartDate === "error"}
                                            labelText={null}
                                            id="input-expiryStartDate"
                                            value={expiryStartDate}
                                            onChange={(date) => this.handleDateChange(date, 'expiryStartDate')}
                                            inputProps={{
                                                placeholder:"Expiry Start Date",
                                                startAdornment: (
                                                    <InputAdornment position="start" className={classes.inputAdornment} >
                                                        <CalenderIcon className={classes.inputAdornmentIcon}/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            formControlProps={{
                                                fullWidth: true,
                                                className: classes.customFormControlClasses
                                            }}
                                        />
                                        <DatePicker
                                            success={validation.expiryEndDate === "success" ? "true" : ""}
                                            error={validation.expiryEndDate === "error"}
                                            labelText={null}
                                            id="input-expiryEndDate"
                                            value={expiryEndDate}
                                            onChange={(date) => this.handleDateChange(date, 'expiryEndDate')}
                                            inputProps={{
                                                placeholder:"Expiry End Date",
                                                startAdornment: (
                                                    <InputAdornment position="start" className={classes.inputAdornment} >
                                                        <CalenderIcon className={classes.inputAdornmentIcon}/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            formControlProps={{
                                                fullWidth: true,
                                                className: classes.customFormControlClasses
                                            }}
                                        />
                                    </div>
                                </>
                            :
                                <></>
                        }
                    </div>
                    <div className={classes.transferFooter+" "+classes.fileSendingFormFooter}>
                        <Button color="white" onClick={() => this.props.clearSearch()} type="button">
                            Clear Search
                        </Button>
                        <Button color="custom" onClick={this.submitSearch} type="button" >
                            {
                                saving ?
                                    <LoaderComponent color="white" align="left" saving={true} />
                                :
                                    "Search"
                            }
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
    render() {
        const { showError } = this.state;
        const { classes } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="userDetails-slide-title"
                aria-describedby="userDetails-slide-description"
            >
                <DialogTitle
                    id="userDetails-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        {" "}
                        <Close className={classes.modalClose} />
                    </Button>
                    <h5 className={classes.modalTitle}>Advanced Search</h5>
                </DialogTitle>
                <DialogContent
                    id="userDetails-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            showError ?
                                this.renderErrorMessages()
                            :
                            <></>
                        }
                        {
                            this.renderForm()
                        }
                </DialogContent>      
            </Dialog>
        );
    }
};

AddRuleModal.defaultProps = {
    open: false,
};
AddRuleModal.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool,
};
export default withStyles(advancedSearchModalStyle)(AddRuleModal);
