import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "../../CustomButtons/Button.jsx";

// core components
import GridContainer from "../../Grid/GridContainer.jsx";
import GridItem from "../../Grid/GridItem.jsx";

const style = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 15px",
        textAlign: "center",
        fontSize: "14px"
    },
    signupButton: {
        marginLeft: "10px"
    }
};

class Account extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            userinfo: this.props.userinfo
        };
    }
    sendState() {
        return this.state;
    }
    isValidated(){
        const { userinfo } = this.state;
        if(userinfo !== null && typeof(userinfo) === "object"){
            return true;
        }
        return false;
    }
    getName(userinfo){
        let name = "";
        if(userinfo.hasOwnProperty('firstName')){
            name = userinfo.firstName;
        }
        if(userinfo.hasOwnProperty('lastName')){
            name += " "+userinfo.lastName;
        }

        return name;
    }
    render() {
        const { classes } = this.props;
        const { userinfo } = this.state;
        return (
            <GridContainer justify="center">
                <GridItem xs={12} sm={12}>
                    {
                        (userinfo !== null && typeof(userinfo) === "object") ?
                            <>
                                <h4 className={classes.infoText}>AdobeSign connected account</h4>
                                <div>
                                    <p>
                                        {this.getName(userinfo)}
                                        {" ("+userinfo.email+")"}
                                    </p>
                                    <Button
                                        color="custom"
                                        onClick={() => this.props.onAdobesignConnect("login")}
                                    >
                                        Switch Account
                                    </Button>
                                </div>
                            </>
                        :
                        <>
                            <h4 className={classes.infoText}>Connect AdobeSign account</h4>
                            <div>
                                <Button
                                    color="custom"
                                    onClick={() => this.props.onAdobesignConnect("login")}
                                >
                                    Log In
                                </Button>
                                <Button
                                    color="custom"
                                    onClick={() => this.props.onAdobesignConnect("signup")}
                                    className={classes.signupButton}
                                >
                                    Sign Up
                                </Button>
                            </div>
                        </>
                    }
                </GridItem>
            </GridContainer>
        );
    }
}

Account.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style)(Account);
