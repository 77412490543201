import React from 'react';
import axios from "axios";
import moment from 'moment';
import Api from "../../../assets/js/utils/Api";
import { Helmet } from 'react-helmet';
import PropTypes from "prop-types";
import Auth from "../../../assets/js/utils/Auth";
import { helper } from '../../../assets/js/utils/Element';
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Button from "../../components/CustomButtons/Button";
import GridItem from "../../components/Grid/GridItem";
import LoaderComponent from "../../components/Loader";
import ExternalLinkModal from "../../components/User/ExternalLinkModal";
import ReactTable from "react-table";
import RefreshIcon from "@material-ui/icons/Refresh";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteExternalLinkModal from "../../components/User/DeleteExternalLinkModal";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from "@material-ui/icons/EditOutlined";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import withStyles from "@material-ui/core/styles/withStyles";
import userHomePageStyle from "../../../assets/jss/user/userHomePageStyle.jsx";

import MaterialIcon from "@mdi/react";
import { mdiContentCopy, mdiDeleteOutline } from '@mdi/js';

const ExternalLinks = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;

        this.state = {
            totalItemCount: 0,
            response: null,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            page: 1,
            checked: [],
            checkedAll: false,
            linkName: false,
            externalLink: null,
            linkModal: false,
            savingLinkModal: false,
            newLinkUploaded: false,
            errorMessage: null,
            deleteModal: false,
            snackbarOpen: false,
            editMode: false,
            linkDetail: this.getDefaultLinkDetail(),
            linkId: null,
            viewPasswords: []
        };

        this.closeSnackbar = this.closeSnackbar.bind(this);
    }
    componentDidMount(){
        const { authorized } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
        }
        this.loadExternalLinks();
    }
    getDefaultLinkDetail(){
        return {
            id: "",
            hash: "",
            url: "",
            title: "",
            linkPassword: "",
            expire: null,
            hideLink: false,
        }
    }
    loadExternalLinks(viewMore = false){
        const source = axios.CancelToken.source();
        let page = this.state.page;
        if(viewMore){
            page += 1;
        }
        const requestData = {
            page: page,
            limit: 50,
        };
        Api.getExternalLinks(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages
            });
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loading: (!viewMore), 
            loadingMore: viewMore,
            cancelToken: source,
            page: page,
        });
    }
    onLinkModal(status = false){
        let state = {
            linkName: status,
            linkModal: status,
            linkDetail: this.getDefaultLinkDetail(),
            editMode: false,
            errorMessage: null
        }

        if(status === false){
            state['checked'] = [];
            state['checkedAll'] = false;
        }

        this.setState(state);
    }
    onExternalLinkModalSuccess(data, editMode = false){
        const source = axios.CancelToken.source();

        this.setState({
            savingLinkModal: true,
            cancelToken: source,
            errorMessage: null
        })

        if(editMode === true) {
            Api.editExternalLink(data, source).then(data => {
                let externalLink = "/external-links/"+data.hash;
                this.setState({
                    savingLinkModal: false, 
                    externalLink: externalLink,
                    linkName: false,
                    editMode: false,
                    cancelToken: null,
                    linkDetail: this.getDefaultLinkDetail(),
                    checked: [],
                    checkedAll: false
                });
            }).catch(err => {
                if(typeof(err) === "object" && err.hasOwnProperty("message")){
                    this.setState({
                        savingLinkModal: false, 
                        cancelToken: null,
                        errorMessage: err.message
                    });
                }
            });
        }else{
            Api.createExternalLink(data, source).then(data => {
                let externalLink = "/external-links/"+data.hash;
                this.setState({
                    savingLinkModal: false, 
                    externalLink: externalLink,
                    linkName: false,
                    editMode: false,
                    cancelToken: null,
                    linkDetail: this.getDefaultLinkDetail(),
                    checked: [],
                    checkedAll: false
                });
            }).catch(err => {
                if(typeof(err) === "object" && err.hasOwnProperty("message")){
                    this.setState({
                        savingLinkModal: false, 
                        cancelToken: null,
                        errorMessage: err.message
                    });
                }
            });
        }
    }
    onLinkModalSuccess(){
        this.setState({
            cancelToken: null,
            linkModal: false,
            savingLinkModal: false,
            linkName: false,
            errorMessage: null,
            newLinkUploaded: false,
            editMode: false,
            linkDetail: this.getDefaultLinkDetail(),
            checked: [],
            checkedAll: false,
            externalLink: null
        }, () => {
            this.loadExternalLinks();
        });
    }
    onToggleAllFiles(e){
        const checkedLinks = [];
        if(e.target.checked){
            const { response } = this.state;
            response.map((link, key) => {
                checkedLinks.push(link.id);
                return null;
            });
        }
        
        this.setState({
            checkedAll: !this.state.checkedAll,
            checked: checkedLinks
        });
    }
    getColumns(){
        const { classes } = this.props;
        const header = (
            <Checkbox
                tabIndex={-1}
                checked={this.state.checkedAll}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                onChange={(e) => this.onToggleAllFiles(e)}
                classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                }}
            />
        );
        let columns = [
            {
                Header: header,
                accessor: "check",
                sortable: false,
                filterable: false,
                headerClassName: "hd_check",
                className: "hd_check td_check",
                resizable: false,
            },
            {
                Header: "Name",
                accessor: "name",
                headerClassName: "hd_title",
                className: "hd_title td_title",
            },
            {
                Header: "Source Path",
                accessor: "url",
                headerClassName: "hd_url",
                className: "hd_url td_url",
            },
            {
                Header: "Password",
                accessor: "password",
                headerClassName: "hd_password",
                className: "hd_password td_password",
            },
            {
                Header: "Expiry Date",
                accessor: "expire_at_orignal",
                headerClassName: "hd_expire hd_show_head",
                className: "hd_expire td_expire td_hide",
            },
            {
                Header: "Expiry Date",
                accessor: "expire_at",
                headerClassName: "hd_expire hd_hide",
                className: "hd_expire td_expire",
                sortMethod: (a, b) => {
                    var a1 = new Date(a).getTime();
                    var b1 = new Date(b).getTime();
                  if(a1<b1)
                    return 1;
                  else if(a1>b1)
                    return -1;
                  else
                    return 0;
                }
            },
            {
                Header: "Created On",
                accessor: "created_at",
                headerClassName: "hd_expire",
                className: "hd_expire td_expire",
            },
            {
                Header: "Actions",
                accessor: "actions",
                headerClassName: "hd_action",
                className: "hd_action td_action",
            },
        ];

        return columns;
    }
    onViewPassword(link){
        const { viewPasswords } = this.state;
        const currentIndex = viewPasswords.indexOf(link.id);
        const newViewPasswords = [...viewPasswords];
    
        if (currentIndex === -1) {
            newViewPasswords.push(link.id);
        } else {
            newViewPasswords.splice(currentIndex, 1);
        }

        this.setState({
            viewPasswords: newViewPasswords
        });
    }
    convertStringToPass(password){
        let string = "";
        for (let i = 0; i < password.length; i++) {
            string +="<span></span>";
        }

        return string;
    }
    getTableData(){
        const { classes } = this.props;
        const { response, checked, viewPasswords } = this.state;
        if(response === null){
            return [];
        }
        let tableData = [];
        response.map(link => {
            let linkArray = {
                id: link.id,
                check: (
                    <Checkbox
                        tabIndex={-1}
                        checked={checked.includes(link.id)}
                        className={classes.positionAbsolute}
                        onClick={() => this.handleToggle(link)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                name: link.name,
                url: link.url,
                hide_link: (link.hide_link ? "Yes" : "No"),
                password: (
                    <>
                        {
                            link.password ?
                                <>  
                                    <div className="sd-password-field">
                                        { 
                                            viewPasswords.includes(link.id) ?
                                                link.password
                                            :
                                                <div className="sd-passwords" dangerouslySetInnerHTML={{__html: this.convertStringToPass(link.password)}}></div>
                                        }
                                    </div>
                                    <Button justIcon color="transparent" onClick={() => this.onViewPassword(link)} title={"View Password"}>
                                        { 
                                            viewPasswords.includes(link.id) ?
                                                <VisibilityOffIcon />
                                            :
                                                <VisibilityIcon />
                                        }
                                    </Button>
                                </>
                            :
                                <></>
                        }
                    </>
                ),
                expire_at_orignal: link.expire_at,
                expire_at: this.getFormatedExpiry(link.expire_at),
                created_at: link.created_at,
                actions: (
                    <>
                        <Button justIcon color="transparent" onClick={() => this.copyViewLink(link)} title={"Copy Link"}>
                            <MaterialIcon path={mdiContentCopy} className="MuiSvgIcon-root" />
                        </Button>
                        <Button justIcon color="transparent" onClick={() => this.onEditModal(true, link)} title="Edit">
                            <EditIcon />
                        </Button>
                        <Button justIcon color="transparent" onClick={() => this.onDeleteModal(true, link.id)} title="Delete">
                            <MaterialIcon path={mdiDeleteOutline} className="MuiSvgIcon-root" />
                        </Button>
                    </>
                ),
            };
            tableData.push(linkArray);
            return null;
        });
        return tableData;
    }
    getTrProps(state, rowInfo){
        if (rowInfo && rowInfo.row) {
            // const { checked } = this.state;
            return {
                onClick: (e) => this.handleToggle(rowInfo.original),
                // style: {
                //     background: checked.includes(rowInfo.original.id) ? 'rgba(0, 0, 0, 0.7)' : 'transparent',
                // }
            }
        }else{
            return {}
        }
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    getFormatedExpiry(expire_at){
        if(!expire_at || expire_at.length <= 0){
            return "No Expiry Date";
        }
        return moment(expire_at).format("MM/DD/YYYY h:mm A");
    }
    onEditModal(status = true, link){
        if(!status){
            this.loadExternalLinks();
            return;
        }
        this.setState({
            linkModal: status,
            linkName: status,
            editMode: status,
            linkDetail: {
                id: link.id,
                hash: link.hash,
                url: link.url,
                title: link.name,
                linkPassword: link.password,
                expire: link.expire_at,
                hideLink: Boolean(Number(link.hide_link)),
            },
        });
    }
    copyViewLink(link){
        helper.copyTextToClipboard(link.viewLink);
        this.setState({snackbarOpen: true});
    }
    closeSnackbar(){
        this.setState({snackbarOpen: false});
    }
    handleToggle(file) {
        if(this.loadingFolder){
            return;
        }
        // if(file.default){
        //     return;
        // }
        const { checked } = this.state;
        const currentIndex = checked.indexOf(file.id);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
            newChecked.push(file.id);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
            checked: newChecked
        });
    }
    onDeleteModal(status = true, linkId = null){
        const { checked } = this.state;

        if(checked.length <= 0 && linkId === null && status === true){
            return;
        }
        this.setState({
            deleteModal: status,
            linkId: linkId
        });
    }
    onDeleteModalSuccess(){
        const { checked, response, linkId } = this.state;
        let id = [linkId];
        if(linkId === null){
            id = checked;
        }
        const source = axios.CancelToken.source();
        const requestData = {
            id: id,
        };
        Api.deleteExternalLinks(requestData, source).then(data => {
            //Handle Success;
        }).catch(err => {
            //Handle Error
        });

        let newResponse = [];
        response.map(link => {
            if(!checked.includes(link.id)){
                newResponse.push(link);
            }
            return null;
        });
        this.setState({
            response: newResponse,
            checked: [],
            deleteModal: false,
            deleteAll: false,
            checkedAll: false
        });
    }
    onNewLink(status = false){
        let { checked, response } = this.state;
        if(checked.length <= 0){
            return;
        }
        checked = checked[0];
        let file = response.filter(x => x.id === checked)[0];

        let state = {
            linkName: status,
            linkModal: status,
            linkDetail: {
                ...this.getDefaultLinkDetail(),
                url: file.url
            },
            editMode: false,
        }

        if(status === false){
            state['checked'] = [];
            state['checkedAll'] = false;
        }

        this.setState(state);
    }
    renderContent(){
        const { classes, sidebar } = this.props;
        const { user } = this.store.getState();
        const { linkModal, savingLinkModal, newLinkUploaded, errorMessage, linkName, externalLink, 
            limit, loading, loadingMore, page, totalPages, deleteModal, checked, linkDetail, editMode } = this.state;
        const tableData = this.getTableData();
        return (
            <>
                <div className={classes.controls}>
                    {
                        sidebar ?
                            <GridItem>
                                <h4>External Links</h4>
                            </GridItem>
                        :
                            null
                    }
                    <GridItem>
                        <div className={classes.controlButtons+" "+(user.theme === 'standard' ? '': 'light-theme-buttons')}>
                            <Button color="transparent" onClick={() => this.onLinkModal(true)} title="Add External Link">
                                <svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.3393 10.1999C22.8866 8.64384 22.8866 6.12301 21.3393 4.56695C19.7919 3.01089 17.2853 3.01089 15.7379 4.56695L15.0385 5.27029C14.8467 5.46324 14.8467 5.78068 15.0385 5.97363C15.2304 6.16658 15.5461 6.16658 15.7379 5.97363L16.4373 5.27029C17.5978 4.10324 19.4794 4.10324 20.6399 5.27029C21.8004 6.43734 21.8004 8.32951 20.6399 9.49656L16.7839 13.3712C15.6265 14.5351 13.7481 14.5351 12.5876 13.3712C11.4859 12.2632 11.424 10.4893 12.4421 9.30672L12.6061 9.11688C12.7856 8.90837 12.764 8.59404 12.5566 8.41354C12.3493 8.23304 12.0367 8.25482 11.8572 8.46334L11.6932 8.65317C10.3346 10.231 10.4182 12.5962 11.8851 14.0714C13.4293 15.6243 15.936 15.6243 17.4802 14.0714L21.3393 10.1999ZM4.53525 10.1999C2.98792 11.756 2.98792 14.2768 4.53525 15.8329C6.08258 17.3889 8.58926 17.3889 10.1366 15.8329L10.836 15.1295C11.0279 14.9366 11.0279 14.6191 10.836 14.4262C10.6441 14.2332 10.3285 14.2332 10.1366 14.4262L9.43719 15.1295C8.2767 16.2966 6.39514 16.2966 5.23465 15.1295C4.07415 13.9625 4.07415 12.0703 5.23465 10.9032L9.09059 7.02864C10.248 5.86471 12.1265 5.86471 13.287 7.02864C14.3887 8.13656 14.4505 9.91048 13.4324 11.0931L13.2684 11.2829C13.0889 11.4914 13.1106 11.8058 13.3179 11.9863C13.5252 12.1668 13.8378 12.145 14.0173 11.9365L14.1813 11.7466C15.5399 10.1688 15.4563 7.80356 13.9894 6.32842C12.439 4.76924 9.93544 4.76924 8.38811 6.32219L4.53525 10.1999Z"/>
                                    <path d="M4.37019 2.70262C4.37019 2.46152 4.17683 2.26672 3.9375 2.26672C3.69817 2.26672 3.50481 2.46152 3.50481 2.70262V4.66416H1.55769C1.31836 4.66416 1.125 4.85895 1.125 5.10006C1.125 5.34116 1.31836 5.53595 1.55769 5.53595H3.50481V7.49749C3.50481 7.7386 3.69817 7.93339 3.9375 7.93339C4.17683 7.93339 4.37019 7.7386 4.37019 7.49749V5.53595H6.31731C6.55664 5.53595 6.75 5.34116 6.75 5.10006C6.75 4.85895 6.55664 4.66416 6.31731 4.66416H4.37019V2.70262Z"/>
                                </svg> 
                                Add
                            </Button>
                            {
                                checked.length > 0 ?
                                    <>
                                        <Button color="transparent" onClick={() => this.onNewLink(true)} title="New Link">
                                            <svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.7858 3.125C11.7858 2.69531 11.4322 2.34375 11.0001 2.34375C10.5679 2.34375 10.2143 2.69531 10.2143 3.125V11.7188H1.57148C1.13934 11.7188 0.785767 12.0703 0.785767 12.5C0.785767 12.9297 1.13934 13.2812 1.57148 13.2812H10.2143V21.875C10.2143 22.3047 10.5679 22.6562 11.0001 22.6562C11.4322 22.6562 11.7858 22.3047 11.7858 21.875V13.2812H20.4286C20.8608 13.2812 21.2143 12.9297 21.2143 12.5C21.2143 12.0703 20.8608 11.7188 20.4286 11.7188H11.7858V3.125Z"/>
                                            </svg>
                                            New Link
                                        </Button>
                                        <Button color="transparent" onClick={() => this.onDeleteModal()} title="Delete">
                                            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.74308 1.125H10.2531C10.4618 1.125 10.6554 1.22344 10.7692 1.38867L11.3498 2.25H5.65022L6.2308 1.38867C6.34085 1.22344 6.53817 1.125 6.74688 1.125H6.74308ZM12.7842 2.25L11.8013 0.794531C11.4674 0.298828 10.883 0 10.2569 0H6.74308C6.11696 0 5.53259 0.298828 5.19866 0.794531L4.21585 2.25H2.43237H1.21429H0.607143C0.273214 2.25 0 2.50312 0 2.8125C0 3.12188 0.273214 3.375 0.607143 3.375H1.30156L2.2692 15.9117C2.35647 17.0895 3.41518 18 4.69018 18H12.3098C13.5848 18 14.6397 17.0895 14.7308 15.9117L15.6984 3.375H16.3929C16.7268 3.375 17 3.12188 17 2.8125C17 2.50312 16.7268 2.25 16.3929 2.25H15.7857H14.5676H12.7842ZM14.4842 3.375L13.5203 15.8309C13.4748 16.418 12.9473 16.875 12.3098 16.875H4.69018C4.05268 16.875 3.52522 16.418 3.47969 15.8309L2.51964 3.375H14.4804H14.4842Z" fill="#556DC2"/>
                                            </svg>
                                            Delete
                                        </Button>
                                    </>
                                :
                                    <></>
                            }
                        </div>
                    </GridItem>
                </div>
                <GridItem>
                    <div className={classes.filesContainer+" external-links"}>
                        <div className={classes.files}>
                            {
                                loading ?
                                    <LoaderComponent color="custom" align="center" />
                                :
                                    <ReactTable
                                        columns={this.getColumns()}
                                        data={tableData}
                                        pageSizeOptions={[500]}
                                        defaultPageSize={limit}
                                        showPaginationTop={false}
                                        minRows={0}
                                        showPaginationBottom={false}
                                        className={"-striped -highlight "+classes.filesTable+" "+classes.externalLinksTable}
                                        getTrProps={(state, rowInfo) => this.getTrProps(state, rowInfo)}
                                        getNoDataProps={() => this.getNoDataProps()}
                                    />
                            }
                            <div className={classes.loadMore}>
                                {
                                    tableData.length > 0 && loadingMore === false && page < totalPages ?
                                        <Button color="custom" id="contentViewMore" round onClick={(e) => this.loadExternalLinks(true)}>
                                            <RefreshIcon className={classes.icons} />View More
                                        </Button>
                                        
                                    :
                                        loadingMore === true ?
                                            <LoaderComponent color="custom" align="center" />
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                    </div>
                </GridItem>
                {
                    linkModal ?
                        <ExternalLinkModal
                            open={linkModal}
                            saving={savingLinkModal}
                            onClose={() => this.onLinkModal(false)} 
                            onSuccess={() => this.onLinkModalSuccess()}
                            onLinkName={(data, editMode) => this.onExternalLinkModalSuccess(data, editMode)}
                            loadingMessage="Please wait while we prepare your external link. It may take few minutes depending on size and number of file(s)."
                            successMessage={ newLinkUploaded === true ? 'Your external link is processing. Please check "Status" in the Link Manager in a few moments. Once "Ready", you may copy the link, and share anywhere.' : 'Your link is now ready to be shared.' }
                            errorMessage={errorMessage}
                            externalLink={externalLink}
                            linkName={linkName}
                            editMode={editMode}
                            linkDetail={linkDetail}
                            store={this.store}
                        />
                    :
                    null
                }
                {
                    deleteModal ?
                        <DeleteExternalLinkModal
                            deleteNote={true}
                            confirm={true}
                            deleteLinks={checked}
                            open={deleteModal}
                            onClose={() => this.onDeleteModal(false)}
                            onSuccess={() => this.onDeleteModalSuccess()}
                        />
                    :
                    null
                }
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    className={classes.snackbar}
                    open={this.state.snackbarOpen}
                    autoHideDuration={1000}
                    onClose={this.closeSnackbar}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">Your link has been copied</span>}
                    action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={this.closeSnackbar}
                    >
                        <CloseIcon />
                    </IconButton>,
                    ]}
                />
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </>
        )
    }
    render() {
        const { classes, sidebar } = this.props;
        const { authorized } = this.store.getState();
        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }

        if(!sidebar){
            return this.renderContent();
        }
        
        return (
            <div className={classes.main}>
                <div className={classes.container}>
                    <div className={classes.content}>
                        {this.renderContent()}
                    </div>
                </div>
            </div>
        )
    }
}

ExternalLinks.defaultProps = {
    sidebar: true
}
ExternalLinks.propTypes = {
    classes: PropTypes.object,
    sidebar: PropTypes.bool
};

export default withStyles(userHomePageStyle)(ExternalLinks);
