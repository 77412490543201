/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Button from "../CustomButtons/Button.jsx";
import DuplicateFileModal from  "../../components/User/DuplicateFileModal";

import styles from "../../../assets/jss/material-kit-pro-react/components/customFileUploadStyle.jsx";
import Config from "../../../../Config.js";

class CustomFileUpload extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        
        this.state = {
            files: this.props.files,
            showPopup: false,
            duplicateFileModal: false,
        };
        this.hiddenFile = React.createRef();
        this.hiddenFileDirectory = React.createRef();

        this.addFile = this.addFile.bind(this);
        this.addFolder = this.addFolder.bind(this);
        this.popupToggle = this.popupToggle.bind(this);
    }
    componentWillReceiveProps(props){
        this.setState({
            files: props.files
        });
    }
    popupToggle(){
        this.setState({
            showPopup: !this.state.showPopup
        });
    }
    onFocus = e => {
        this.hiddenFile.current.click(e);
    };
    onDirectoryFocus = e => {
        this.hiddenFileDirectory.current.click(e);
    };
    handleSubmit = e => {
        e.preventDefault();
        // this.state.file is the file/image uploaded
        // in this function you can save the image (this.state.file) on form submit
        // you have to call it yourself
    };
    hasFile(file, filesArray){
        let fileName = file.name;
        let hasFile = false;
        if(filesArray.length > 0){
            filesArray.map((item, key) => {
                if(item.type === 'file' && item.file.name === fileName){
                    hasFile = true;
                }
                return null;
            })
        }

        return hasFile;
    }
    hasFolder(folderName, filesArray){
        let hasFolder = false;
        if(filesArray.length > 0){
            filesArray.map((item, key) => {
                if(item.type === 'folder' && item.name === folderName){
                    hasFolder = true;
                }
                return null;
            })
        }

        return hasFolder;
    }
    addFile = e => {
        let files = e.target.files;
        let filesArray = this.state.files;
        let hasFile = false;
        let duplicateFileModal = false;
        const disallowedExtensions = Config.getDisallowedExtensions();
        for (let i = 0; i < files.length; i++) {
            if(disallowedExtensions && disallowedExtensions.length > 0){
                const extension = files[i].name.split('.').pop();
                if(disallowedExtensions.includes(extension)){
                    continue;
                }
            }
            
            hasFile = this.hasFile(files[i], filesArray);
            if(hasFile === true){
                duplicateFileModal = true;
                continue;
            }
            filesArray.push({
                type: "file",
                file: files[i]
            });
        }

        this.props.onChange(filesArray);
        this.setState({
            files: filesArray,
            duplicateFileModal: duplicateFileModal
        });
    };
    addFolder = e => {
        let files = e.target.files;
        if(files.length <= 0){
            return;
        }
        let newFilesArray = [];
        const disallowedExtensions = Config.getDisallowedExtensions();
        for (let i = 0; i < files.length; i++) {
            if(disallowedExtensions && disallowedExtensions.length > 0){
                const extension = files[i].name.split('.').pop();
                if(disallowedExtensions.includes(extension)){
                    continue;
                }
            }
            newFilesArray.push(files[i]);
        }
        let filesArray = this.state.files;
        var folderPath = files[0].webkitRelativePath;
        var folder = folderPath.split("/")[0];
        let hasFolder = this.hasFolder(folder, filesArray);
        if(hasFolder === false){
            filesArray.push({
                type: "folder",
                name: folder,
                files: newFilesArray
            });
        }
        this.props.onChange(filesArray);
        this.setState({
            files: filesArray,
            duplicateFileModal: hasFolder
        });
    }
    filesList(files){
        let filesLists = [];
        for (var i = 0; i < files.length; i++) {
            let file = files[i];
            filesLists.push(file);
        }
        return filesLists;
    }
    onDuplicateFileModal(status = false){
        this.setState({
            duplicateFileModal: status,
        })
    }
    render() {
        const { classes, multiple, uploadFileModal, uploadType } = this.props;
        const { duplicateFileModal } = this.state;

        return (
            <div className={classes.inputFileWrapper+" "+(false ? classes.mobile : "")}>
                <input
                    type="file"
                    className={classes.inputFile}
                    multiple={multiple}
                    ref={this.hiddenFile}
                    onChange={this.addFile}
                    value=''
                />
                <input
                    type="file"
                    className={classes.inputFile}
                    multiple={multiple}
                    ref={this.hiddenFileDirectory}
                    onChange={this.addFolder}
                    webkitdirectory="webkitdirectory"
                    directory="directory"
                    value=''
                />
                {
                    uploadFileModal ?
                        <div className={classes.addFilesSection}>
                            <svg width="21" height="28" viewBox="0 0 21 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.4" d="M0 3.5C0 1.56953 1.56953 0 3.5 0H12.25V7C12.25 7.96797 13.032 8.75 14 8.75H21V24.5C21 26.4305 19.4305 28 17.5 28H3.5C1.56953 28 0 26.4305 0 24.5V3.5ZM5.25 16.625C5.25 16.9586 5.37578 17.2977 5.63281 17.5547C6.14687 18.0688 6.97813 18.0688 7.48672 17.5547L9.18203 15.8594L9.1875 21.4375C9.1875 22.1648 9.77266 22.75 10.5 22.75C11.2273 22.75 11.8125 22.1648 11.8125 21.4375V15.8539L13.5078 17.5492C14.0219 18.0633 14.8531 18.0633 15.3617 17.5492C15.8703 17.0352 15.8758 16.2039 15.3617 15.6953L11.4242 11.7578C10.9102 11.2438 10.0789 11.2438 9.57031 11.7578L5.63281 15.6953C5.37578 15.9523 5.25 16.2859 5.25 16.625Z" fill="#556DC2"/>
                                <path d="M21.0001 8.75H14.0001C13.0321 8.75 12.2501 7.96797 12.2501 7V0L21.0001 8.75ZM11.8126 21.4375C11.8126 22.1648 11.2274 22.75 10.5001 22.75C9.77276 22.75 9.1876 22.1648 9.1876 21.4375V15.8539L7.49229 17.5492C6.97823 18.0633 6.14698 18.0633 5.63838 17.5492C5.12979 17.0352 5.12432 16.2039 5.63838 15.6953L9.57588 11.7578C10.0899 11.2437 10.9212 11.2437 11.4298 11.7578L15.3673 15.6953C15.8814 16.2094 15.8814 17.0406 15.3673 17.5492C14.8532 18.0578 14.022 18.0633 13.5134 17.5492L11.8181 15.8539L11.8126 21.4375Z" fill="#556DC2"/>
                            </svg>
                            <p>
                                Drag and Drop files here or
                                {
                                    uploadType === 'file' ?
                                        <Button link onClick={this.onFocus} className="add-file-title">
                                            Choose file
                                        </Button>   
                                    :
                                        <Button link onClick={this.onDirectoryFocus} className="add-file-title">
                                            Choose Folder
                                        </Button>   
                                }
                            </p>
                        </div>
                    :
                        <div className={classes.addFilesButton+" add-files-button"}>
                            <ul>
                                <li>
                                    <Button onClick={this.onFocus} className="add-file-title">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 2560 2560">
                                            <path d="M1885 2540c-234 -32 -430 -218 -480 -455 -19 -88 -19 -142 0 -230 48 -227 223 -402 450 -450 88 -19 142 -19 230 0 205 44 373 195 436 395 29 91 29 249 0 340 -86 271 -353 438 -636 400zm175 -380v-100h200v-180h-200v-200h-180v200h-200v180h200v200h180v-100z"/>
                                            <path d="M310 2456c-121 -27 -225 -111 -278 -224l-27 -57V285l27 -57C69 148 139 76 219 37l66 -32 424 -3 425 -3 55 27c45 22 111 82 342 313 239 239 291 296 315 346l29 60 3 231 4 230 -84 22c-46 11 -93 24 -105 28l-23 6v-200c0 -195 -1 -202 -23 -227 -32 -37 -79 -44 -310 -45h-199l-29 -29 -29 -29V523c0 -215 -7 -270 -41 -304 -18 -18 -36 -19 -348 -19H362l-53 28c-43 22 -59 38 -81 81l-28 53v1733l24 52c17 36 38 60 67 80l43 28 467 3 467 3 6 22c3 12 28 56 56 99 27 42 50 79 50 82 0 12 -1013 5 -1070 -8z"/>
                                        </svg>
                                        Add files
                                    </Button>
                                </li>
                                <li className="or-text">or</li>
                                <li>
                                    <Button onClick={this.onDirectoryFocus} className="select-folder">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 2560 2560">
                                            <path d="M1885 2540c-234-32-430-218-480-455-19-88-19-142 0-230 48-227 223-402 450-450 88-19 142-19 230 0 205 44 373 195 436 395 29 91 29 249 0 340-86 271-353 438-636 400zm175-380v-100h200v-180h-200v-200h-180v200h-200v180h200v200h180v-100z"/>
                                            <path d="M188 2151c-73-29-123-74-157-143l-31-61v-857C0 113-5 174 84 84 166 2 180 0 549 0c312 0 317 1 366 24 87 41 152 128 170 229l7 37h557c619 0 619 0 704 65 32 25 55 55 79 102l33 68 3 427c2 236 0 428-3 428-4 0-41-23-83-50-43-28-87-53-99-56l-22-6-3-360-3-360-28-24-28-24h-967c-1057 0-996-3-1021 57-8 21-11 206-9 690l3 662 23 23 23 23 477 5 477 5 6 75c4 41 10 87 14 103l7 27H734c-461-1-503-2-546-19z"/>
                                        </svg>
                                        Select a folder
                                    </Button>
                                </li>
                            </ul>
                        </div>
                }
                {
                    duplicateFileModal === true ? 
                        <DuplicateFileModal 
                            open={duplicateFileModal} 
                            onClose={() => this.onDuplicateFileModal(false)} 
                            successMessage={"The File/folder has already been added to the list to be uploaded!"}
                        />
                    :
                        <></>
                }
            </div>
        );
    }
}

CustomFileUpload.defaultProps = {
    multiple: false,
    onChange: function(){  },
    uploadFileModal: false,
    uploadType: 'file'
};

CustomFileUpload.propTypes = {
    classes: PropTypes.object,
    id: PropTypes.string,
    endButton: PropTypes.object,
    startButton: PropTypes.object,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    uploadFileModal: PropTypes.bool,
    uploadType: PropTypes.string
};

export default withStyles(styles)(CustomFileUpload);
