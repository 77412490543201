import React from "react";
import Api from "../../../assets/js/utils/Api";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import LoaderComponent from '../Loader';
import GridItem from "../Grid/GridItem.jsx";
import axios from "axios";
import CustomInput from "../CustomInput/CustomInput.jsx";
import CustomSelect from "../../components/CustomInput/CustomSelect";

import addLoginVideoModelStyle from "../../../assets/jss/user/addLoginVideoModelStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const AddLoginVideoModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        const { loginVideoDetails, allAccountTypes, accountTypes, editMode } = this.props;
        
        this.state = {
            allAccountTypes: allAccountTypes,
            accountTypes: accountTypes,
            loginVideoDetails: loginVideoDetails,
            saving: false,
            response: null,
            success: false,
            cancelToken: null,
            showError: false,
            errorMessage: "",
            validation: {
                type: "",
                title: "",
                video_url: "",
                isValid: false
            },
            editMode: editMode
        };

        this.updateLoginVideo = this.updateLoginVideo.bind(this);
    }
    updateLoginVideo(){
        const isValid = this.validateForm();
        if(!isValid){
            return;
        }

        const { loginVideoDetails } = this.state;
        
        const source = axios.CancelToken.source();
        this.setState({
            saving: true,
            cancelToken: source,
            showError: false,
        });

        Api.updateLoginVideo(loginVideoDetails, source).then(data => {
            this.setState({
                success: true,
                saving: false
            });
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                this.setState({
                    saving: false,
                    showError: true,
                    errorMessage: err.message
                });
            }
        });
    }
    validateForm(){
        let validation = {
            title: "success",
            code: "success",
            type: "success",
            isValid: true
        };
        const { loginVideoDetails } = this.state;
        if(loginVideoDetails.type.length <= 0){
            validation.type = "error";
            validation.isValid = false;
        }
        
        if(loginVideoDetails.title.length <= 0){
            validation.title = "error";
            validation.isValid = false;
        }
        if(loginVideoDetails.video_url.length <= 0){
            validation.video_url = "error";
            validation.isValid = false;
        }

        let isValid = true;
        if(this.state.loginVideoDetails.additionalVideos.length > 0 ){
            this.state.loginVideoDetails.additionalVideos.map(additionalVideo => {
                if(additionalVideo.title.length <= 0){
                    isValid = false;
                }
                if(additionalVideo.video_url.length <= 0){
                    isValid = false;
                }

                return null;
            });
        }

        if(isValid === false){
            validation.isValid = false;
        }

        this.setState({
            validation: validation,
            isValidate: true
        });

        return validation.isValid;
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<GridItem key={key} className={"passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </GridItem>);
                key++;
            }
            return errorMessages;
        }
        return <GridItem className={"passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </GridItem>;
    }
    handleChange(e, name){
        this.setState({
            loginVideoDetails: {
                ...this.state.loginVideoDetails,
                [name]: e.target.value
            }
        });
    }
    handleAdditionalChange(e, key, name){
        let { additionalVideos }  = this.state.loginVideoDetails;
        let value = e.target.value;
        additionalVideos[key][name] = value;

        this.setState({
            loginVideoDetails: {
                ...this.state.loginVideoDetails,
                additionalVideos: additionalVideos
            }
        });
    }
    cloneInputs(){
        this.setState({
            loginVideoDetails: {
                ...this.state.loginVideoDetails,
                additionalVideos: [
                    ...this.state.loginVideoDetails.additionalVideos,
                    {
                        title: "",
                        video_url: ""
                    }
                ]
            }
        })
    }
    removeAdditonalVideo(){
        let { additionalVideos }  = this.state.loginVideoDetails;
        additionalVideos.splice(additionalVideos.length-1, 1);

        this.setState({
            loginVideoDetails: {
                ...this.state.loginVideoDetails,
                additionalVideos: additionalVideos
            }
        });
    }
    renderForm(){
        const { saving, loginVideoDetails, validation, isValidate, allAccountTypes, accountTypes, editMode } = this.state;
        const { classes } = this.props;

        return(
            <div className={classes.addUserContainer}>
                <div className={classes.addUserForm}>
                    <div>
                        {
                            editMode ?
                                <CustomSelect
                                    success={validation.type === "success"}
                                    error={validation.type === "error"}
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.customSelectFormControl
                                    }}
                                    labelText="Type"
                                    selectProps={{
                                        onChange: (e) => (e),
                                        value: loginVideoDetails.type
                                    }}
                                    inputProps={{
                                        name: "type",
                                        id: "input-type",
                                        className: classes.alignLeft,
                                        disabled: true
                                    }}
                                    color="info"
                                    items={allAccountTypes}
                                    itemLabel="label"
                                    itemValue="id"
                                />
                            :
                                <CustomSelect 
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    success={validation.type === "success"}
                                    error={validation.type === "error"}
                                    labelText="Type"
                                    selectProps={{
                                        onChange: (e) => this.handleChange(e,"type"),
                                        value: loginVideoDetails.type
                                    }}
                                    inputProps={{
                                        name: "type",
                                        id: "input-type",
                                        className: classes.textLeft
                                    }}
                                    items={accountTypes}
                                    itemLabel="label"
                                    itemValue="id"
                                />
                        }
                        <CustomInput
                            success={validation.title === "success"}
                            error={validation.title === "error"}
                            id="input-title"
                            labelText="Title"
                            inputProps={{
                                onChange: (e) => this.handleChange(e,'title'),
                                value: loginVideoDetails.title,
                                name: "title",
                                type: "text",
                            }}
                            color="info"
                            formControlProps={{
                                fullWidth: true,
                                className: "custom-input"
                            }}
                        />
                        <CustomInput
                            success={validation.video_url === "success"}
                            error={validation.video_url === "error"}
                            id="input-videourl"
                            labelText="Video Url"
                            inputProps={{
                                onChange: (e) => this.handleChange(e,'video_url'),
                                value: loginVideoDetails.video_url,
                                name: "code",
                                type: "text",
                            }}
                            color="info"
                            formControlProps={{
                                fullWidth: true,
                                className: "custom-input"
                            }}
                        />
                        <div className={classes.additionalVideosList}>
                            <p className={classes.textLeft}>Additional Videos</p>
                            {
                                loginVideoDetails.additionalVideos.length > 0 ?
                                    loginVideoDetails.additionalVideos.map((additionalVideo, key) => (
                                        <div className="additional-video" key={key}>
                                            <CustomInput
                                                error={isValidate && additionalVideo.title.length <= 0}
                                                id="input-title"
                                                labelText="Title"
                                                inputProps={{
                                                    onChange: (e) => this.handleAdditionalChange(e, key, 'title'),
                                                    value: additionalVideo.title,
                                                    name: "title",
                                                    type: "text",
                                                }}
                                                color="info"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: "custom-input"
                                                }}
                                            />
                                            <CustomInput
                                                error={isValidate && additionalVideo.video_url.length <= 0}
                                                id="input-videourl"
                                                labelText="Video Url"
                                                inputProps={{
                                                    onChange: (e) => this.handleAdditionalChange(e, key, 'video_url'),
                                                    value: additionalVideo.video_url,
                                                    name: "code",
                                                    type: "text",
                                                }}
                                                color="info"
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className: "custom-input"
                                                }}
                                            />
                                        </div>
                                    ))
                                :
                                    <></>
                            }
                            <div className="clone-input-buttons">
                                <Button color="info" onClick={() => this.cloneInputs()}>
                                    Add Another
                                </Button>
                                {
                                    loginVideoDetails.additionalVideos.length > 0 ?
                                        <Button color="info" onClick={() => this.removeAdditonalVideo()}>
                                            Remove Additional Video
                                        </Button>
                                    :
                                        <></>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={classes.transferFooter+" "+classes.fileSendingFormFooter}>
                        <Button color="custom" onClick={this.updateLoginVideo} type="button">
                            {
                                saving ?
                                    <LoaderComponent color="white" align="left" saving={true} />
                                :
                                    "Save Changes"
                            }
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
    render() {
        const { success, showError, editMode } = this.state;
        const { classes } = this.props;

        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="userDetails-slide-title"
                aria-describedby="userDetails-slide-description"
            >
                <DialogTitle
                    id="userDetails-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        {" "}
                        <Close className={classes.modalClose} />
                    </Button>
                    <h5 className={classes.modalTitle}>
                        {
                            editMode ?
                                "Edit Login Video"
                            :
                            "Add Login Video"
                        }
                    </h5>
                </DialogTitle>
                <DialogContent
                    id="userDetails-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            showError ?
                                this.renderErrorMessages()
                            :
                            <></>
                        }
                        {
                            success ?
                                <>
                                    <p className={classes.successMessage}>Login Videos added successfully.</p>
                                    <Button color="custom" onClick={() => this.props.onClose()}>
                                        Close
                                    </Button>
                                </>
                            :
                            this.renderForm()
                        }
                </DialogContent>      
            </Dialog>
        );
    }
};

AddLoginVideoModal.defaultProps = {
    open: false,
    editMode: false,
};
AddLoginVideoModal.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool,
    loginVideoDetails: PropTypes.object.isRequired,
    editMode: PropTypes.bool
};
export default withStyles(addLoginVideoModelStyle)(AddLoginVideoModal);
