import { whiteColor, grayColor, infoColor, successColor, hexToRgb } from "../material-kit-pro-react.jsx";

import homePageStyle from "../material-kit-pro-react/views/homePageStyle.jsx";
import customCheckboxRadioSwitchStyle from "../material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
import modalStyle from "../material-kit-pro-react/modalStyle.jsx";

const uploadFileModalStyle = theme => ({
    ...customCheckboxRadioSwitchStyle,
    ...homePageStyle(theme),
    ...modalStyle(theme),
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "550px !important",
        padding: "25px 28px 30px",
    },
    modalHeader: {
        padding: "0px",
        position: "relative",
    },
    modalTitle: {
        ...modalStyle(theme).modalTitle,
        textAlign: "left",
        fontWeight: "600",
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: "30px",
        paddingBottom: "30px",
        "& .sd_loader": {
            width: "300px !important",
            height: "300px !important",
            [theme.breakpoints.down("xs")]: {
                width: "180px !important",
                height: "180px !important",
            },
            "& > div": {
                width: "250px !important",
                height: "250px !important",
                [theme.breakpoints.down("xs")]: {
                    width: "150px !important",
                    height: "150px !important",
                },
            },
            "& svg": {
                "& circle": {
                    strokeWidth: "1px"
                }
            }
        },
        "& .note": {
            fontSize: "12px",
            opacity: "0.5"
        },
        "& .MuiFormControlLabel-label": {
            textTransform: "uppercase",
            color: theme.customize.whiteDGrayColor,
            opacity: "1",
            padding: "0px"
        }
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        justifyContent: "space-between !important",
        MsFlexPack: "space-between !important",
        WebkitBoxPack: "space-between !important",
        padding: "0px",
        borderTop: "1px solid #D8D9E0",
        paddingTop: "17px",
        "& button": {
            maxWidth: "none",
            "& .MuiButton-label": {
                display: "flex",
                columnGap: "10px",
            },
            "& svg": {
                width: "auto",
                height: "auto",
                margin: "0px",
            }
        },
        "& .MuiFormControlLabel-label": {
            textTransform: "uppercase",
            color: theme.customize.whiteDGrayColor
        }
    },
    instructionNoticeModal: {
        marginBottom: "25px"
    },
    imageNoticeModal: {
        maxWidth: "150px"
    },
    modalLarge: {
        [theme.breakpoints.up("md")]: {
            maxWidth: "800px"
        }
    },
    modalSmall: {
        [theme.breakpoints.up("sm")]: {
            width: "300px",
            margin: "auto"
        },
        margin: "0 auto"
    },
    modalSmallBody: {
        marginTop: "20px"
    },
    modalSmallFooterFirstButton: {
        margin: "0",
        paddingLeft: "16px",
        paddingRight: "16px",
        width: "auto"
    },
    modalSmallFooterSecondButton: {
        marginBottom: "0",
        marginLeft: "5px"
    },
    modalLogin: {
        maxWidth: "360px",
        overflowY: "visible",
        width: "100%",
        "& $modalCloseButton": {
            color: whiteColor,
            top: "-10px",
            right: "10px",
            textShadow: "none",
            position: "relative"
        },
        "& $modalHeader": {
            borderBottom: "none",
            paddingTop: "24px",
            paddingRight: "24px",
            paddingBottom: "0",
            paddingLeft: "24px"
        },
        "& $modalBody": {
            paddingBottom: "0",
            paddingTop: "0"
        },
        "& $modalFooter": {
            paddingBottom: "0",
            paddingTop: "0"
        }
    },
    modalLoginCard: {
        marginBottom: "0",
        margin: "0",
        "& $modalHeader": {
            paddingTop: "0"
        }
    },
    modalSignup: {
        maxWidth: "900px",
        width: "100%",
        "& $modalHeader": {
            paddingTop: "0"
        },
        "& $modalTitle": {
            textAlign: "center",
            width: "100%",
            marginTop: "0.625rem"
        },
        "& $modalBody": {
            paddingBottom: "0",
            paddingTop: "0"
        }
    },
    modalSignupCard: {
        padding: "40px 0",
        margin: "0"
    },
    modalCloseButtonCustom: {
        position: "absolute",
        top: "0px",
        right: "0px",
        margin: "0px",
        padding: "0px",
        "& svg": {
            marginRight: "0px"
        }
    },
    transferFooter: {
        ...homePageStyle(theme).transferFooter,
        border: "none !important",
        "& svg": {
            transform: "none !important",
            marginTop: "0px !important"
        },
        "& .MuiFormControlLabel-label.Mui-disabled": {
            color: grayColor[12]
        },
        "& button.disable-btn": {
            fontStyle: "italic",
            "& svg": {
                transform: 'skewX(-20deg) !important'
            }
        },
    },
    uploadingSuccessFooter: {
        display: "flex",
        justifyContent: "flex-end !important",
        MsFlexPack: "flex-end !important",
        WebkitBoxPack: "flex-end !important",
        columnGap: "9px",
        padding: "0px 12px"
    },
    filesTable: {
        marginBottom: "10px",
        "& .rt-table": {
            "&:not(:hover)": {
                "&::-webkit-scrollbar": {
                    width: "15px",
                },
                "&::-webkit-scrollbar-track": {
                    backgroundColor: "rgba(0,0,0,0.3)",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "rgba(0,0,0,0.1)",
                    
                }
            }
        },
        "& .rt-thead .rt-th": {
            fontSize: "12px",
            color: theme.customize.color,
            fontWeight: "bold",
            padding: "0px"
        },
        "& .rt-tbody .rt-td, & .rt-tbody .rt-td a": {
            fontSize: "12px",
            color: theme.customize.color,
        },
        "& .rt-tbody .rt-td": {
            padding: "0px",
            margin: "5px 0px",
            "&.td_name": {
                "& .react-contextmenu-wrapper": {
                    padding: "5px 5px",
                    margin: "0px",
                    "&>div": {
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        display: "block",
                        width: "100%",
                    }
                }
            }
        },
        "& .hd_check": {
            width: "20px !important",
            flex: "20 0 auto !important",
            textAlign: "center !important",
        },
        "& .hd_name": {
            textAlign: "left !important",
            width: "80px !Important",
            flex: "80 0 auto !important",
        },
        "& .hd_email": {
            textAlign: "left !important",
            width: "120px !Important",
            flex: "120 0 auto !important",
        },
        "& .td_check": {
            "& .MuiButtonBase-root": {
                padding: "0px",
            }            
        },
        "& .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after": {
            color: grayColor[17],
            opacity: "1 !important"
        },
        "& .rt-td": {
            fontSize: "12px"
        },
        "& .rt-tr": {
            cursor: "pointer"
        },
        "& .rt-tbody": {
            overflow: "unset",
            marginBottom: "5px",
            maxHeight: "200px",
            "& .rt-tr-group": {
                flexGrow: "0",
            }
        },
        [theme.breakpoints.down("xs")]: {
            padding: "10px"
        },
        "&.usersTable": {
            "& .left": {
                textAlign: "left"
            }
        },
    },
    uncheckedIcon: {
        ...customCheckboxRadioSwitchStyle.uncheckedIcon,
        borderColor: theme.customize.color,
    },
    checkedIcon: {
        ...customCheckboxRadioSwitchStyle.checkedIcon,
        borderColor: "#2171C6",
        background: "#2171C6",
        fill: whiteColor
    },
    zipName: {
        "& input": {
            color: theme.customize.color
        },
        "& .MuiInput-underline": {
            border: "1px solid "+grayColor[2],
            paddingLeft: "10px",
            borderRadius: "3px",
            "&:before": {
                display: "none !important"
            },
            "&:after": {
                bottom: "-1px",
                borderBottomWidth: "1px"
            }
        }
    },
    zipPassword: {
        "& .MuiFormControl-root": {
            paddingTop: "0px"
        },
        "& .viewPasswordIcon": {
            cursor: "pointer",
            marginRight: "5px"
        },
    },
    removeButtonBorder: {
        "& button": {
            border: "0px !important"
        }
    },
    inlineFields: {
        "& .MuiFormControl-root": {
            width: "calc(50% - 5px)",
            "&:first-child": {
                marginRight: "5px",
            },
            "&:first-child + div": {
                marginLeft: "5px"
            },
            "& .MuiTextField-root": {
                width: "100%"
            }
        }
    },
    inputAdornmentIcon: {
        color: whiteColor+" !important"
    },
    modalDuplicates: {
        maxWidth: "600px",
        width: "100%"
    },
    duplicates: {
        color: theme.customize.color,
        "& ul": {
            listStyleType: "none",
            paddingLeft: "0px",
            textAlign: "left",
            "& li": {
                cursor: "pointer",
                padding: "8px 10px",
                display: "flex",
                alignItems: "center",
                transition: "all 0.3s ease",
                "& svg": {
                    marginRight: "5px"
                },
                "&.active": {
                    background: infoColor[0]
                },
                "&:hover:not(.active)": {
                    background: infoColor[8]
                }
            }
        }
    },
    checkIcon: {
        color: successColor[0]
    },
    skipIcon: {
        color: infoColor[2]
    },
    duplicateSelection: {
        color: theme.customize.color,
        "& ul": {
            listStyleType: "none",
            paddingLeft: "0px",
            textAlign: "left",
            "& li": {
                cursor: "pointer",
                padding: "8px 0px",
                marginTop: "8px",
                borderTop: "1px solid "+grayColor[4]
            }
        }
    },
    selectiveCheckboxes: {
        display: "flex",
        "& $label": {
            whiteSpace: "nowrap",
            paddingLeft: "0px",
            color: theme.customize.color
        },
        "& $checkRoot": {
            padding: "5px"
        },
        "& .MuiFormControlLabel-root": {
            marginLeft: "0px",
            width: "50%"
        }
    },
    duplicateFile: {
        display: "flex",
        "& $label": {
            paddingLeft: "0px",
            color: theme.customize.color
        },
        "& $checkRoot": {
            padding: "5px"
        },
        "& .MuiFormControlLabel-root": {
            marginLeft: "0px",
            width: "50%"
        }
    },
    duplicateFileName: {
        fontSize: "14px",
        paddingLeft: "5px"
    },
    selectionButtons: {
        display: "flex",
        justifyContent: "flex-end",
        "& button": {
            marginLeft: "10px"
        }
    },
    addBtn: {
        color: theme.customize.color+" !Important",
        border: "none !important",
        "& span": {
            fontSize: "14px"
        }
    },
    fileSendingForm: {
        "& .add-files-button": {
            "& ul": {
                backgroundColor: theme.customize.modalBackground,
                border: "none"
            }
        },
        "& button": {
            color: theme.customize.color,
            "&:focus, &:hover": {
                color: theme.customize.color,
            }
        },
        "& $storageExceedMessage": {
            border: "none"
        },
        "& .uploader-list": {
            border: "none",
            "& .uploader-add-files-count": {
                backgroundColor: theme.customize.modalBackground,
                display: "flex",
                justifyContent: "space-between",
                "& span": {
                    margin: "0px",
                    border: "none",
                    padding: "0px",
                    maxWidth: "100%",
                    color: theme.customize.whiteGrayColor,
                    fontSize: "12px",
                    opacity: "1",
                    "&.remaining": {
                        textAlign: "right",
                    }
                }
            },
            "& .file": {
                backgroundColor: theme.customize.modalBackground,
                "&:hover": {
                    backgroundColor: theme.customize.modalBackground
                },
                "& span": {
                    color: theme.customize.color
                }
            }
        },
    },
    filesList: {
        ...homePageStyle(theme).filesList,
        marginTop: "25px",
        "& .title": {
            fontSize: "16px",
            color: theme.customize.whiteDBlueColor,
            fontWeight: "500",
            opacity: "1",
            lineHeight: "normal",
            marginBottom: "0px",
        },
        "& li": {
            marginTop: "14px"
        },
        "& .file": {
            paddingLeft: "0px",
            paddingRight: "0px",
            "& span": {
                opacity: "1",
                lineHeight: "normal",
            }
        },
        "& .file-header": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            minWidth: "0",
        },
        "& .file-details": {
            display: "flex",
            alignItems: "center",
            columnGap: "12px",
            overflow: "hidden",
        },
        "& .file-detail": {
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            overflow: "hidden",
            "& .file-name": {
                fontSize: "14px",
                fontWeight: "500",
                color: theme.customize.whiteBlackColor+" !important",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                maxWidth: "100%",
            }
        },
        "& .filelist-action-delete": {
            cursor: "pointer",
            display: "block",
            "& svg": {
                color: theme.customize.gridIconColor,
                fill: theme.customize.gridIconColor
            },
            "&.uploading-action": {
                "& svg": {
                    color: "#53E13C",
                    fill: "#53E13C",
                }
            },
        },
        "& .file-icon": {
            background: "#556DC21A",
            borderRadius: "5px",
            "&  button": {
                padding: "0px",
                margin: "0px"
            },
        },
        "& .file-info": {
            "& span": {
                fontSize: "12px",
                color: theme.customize.gridIconColor+" !important",
            }
        }
    },
    uploadingProgress: {
        ...homePageStyle(theme).uploadingProgress,
        border: "none"
    },
    separator: {
        borderTop: "1px solid "+grayColor[17]
    },
    sendButton: {
        borderRadius: "5px",
    },
    dragndropSection: {
        border: "1px dashed rgba(" + hexToRgb(theme.customize.whiteBlackColor) + ", 0.4)",
        borderRadius: "5px"
    },
    uploaderCompleteContent: {
        ...homePageStyle(theme).uploaderCompleteContent,
        padding: "0px 12px",
        textAlign: "center"
    },
    uploadingContent: {
        ...homePageStyle(theme).uploadingContent,
        textAlign: "center"
    },
    uploadFilesModal: {
        position: "fixed",
        right: "60px",
        bottom: "60px",
        backgroundColor: theme.customize.blackWhiteColor,
        zIndex: 10000000000,
        borderRadius: "10px",
        maxWidth: "320px",
        width: "100%",
        columnGap: "20px",
        alignItems: "center",
        boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
        overflow: "hidden",
        padding: "10px 0px 5px",
        "& $modalHeader": {
            padding: "0px 12px 14px",
            borderBottom: "1px solid #D8D9E0",
            "& $modalCloseButton": {
                right: "12px"
            }
        },
        "& .upload-files-section": {
            minHeight: "170px",
            padding: "8px 12px 20px",
            "& .files-listings": {
                overflowY: "auto",
                maxHeight: "270px",
            }
        },
        "& .time-left-section": {
            padding: "0px 12px",
        },
        "& .time-left-content": {
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "3px",
            "& p":{
                fontSize: "12px",
                margin: "0px",
                color: theme.customize.whiteBlackColor,
                lineHeight: "normal",
            }
        },
        "& .progress-bar": {
            backgroundColor: "#D9D9D9",
            width: "100%",
            height: "6px",
            borderRadius: "5px",
            position: "relative",
        },
        "& .progress-width": {
            backgroundColor: "#556DC2",
            position: "absolute",
            height: "6px",
            left: "0px",
            borderRadius: "5px",
        },
        "& $filesList": {
            marginTop: "0px",
            "& li:first-child": {
                marginTop: "0px"
            }
        },
        "& .success-title-section": {
            display: "flex",
            alignItems: "center",
            columnGap: "3px",
            "& h4": {
                fontSize: "16px",
            },
            "& p": {
                marginBottom: "0px",
                fontSize: "12px",
                color: "rgba("+hexToRgb(theme.customize.whiteBlackColor)+", 0.8)",
                lineHeight: "normal",
                marginTop: "1px"
            }
        }
    }
});

export default uploadFileModalStyle;
