import { dangerColor, successColor, primaryColor } from "../material-kit-pro-react.jsx";
import modalStyle from "../material-kit-pro-react/modalStyle.jsx";
  
const confirmationModalStyle = theme => ({
    ...modalStyle(theme),
    modalRoot: {
        overflow: "auto",
        display: "block"
    },
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "550px !important",
        padding: "25px 28px 30px",
    },
    modalHeader: {
        padding: "0px",
        position: "relative",
    },
    modalTitle: {
        ...modalStyle(theme).modalTitle,
        textAlign: "left",
        fontWeight: "600",
    },
    modalCloseButtonCustom: {
        position: "absolute",
        top: "0px",
        right: "0px",
        margin: "0px",
        padding: "0px",
        "& svg": {
            marginRight: "0px"
        }
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: "25px",
        paddingBottom: "20px",
        textAlign: "left",
        "& p": {
            color: theme.customize.whiteBlackColor,
            opacity: "0.8",
            "&$danger": {
                color: dangerColor[0],
                opacity: "1",
            },
            marginBottom: "18px",
            "& span": {
                fontWeight: "700"
            },            
            "&.delete-note-1": {
                marginBottom: "0px",
                marginTop: "20px"
            }
        },
        "& .items-lists": {
            paddingLeft: "23px",
            "& ul": {
                marginBottom: "0px"
            },
            "& p": {
                marginBottom: "0px",
                lineHeight: "normal"
            }
        },
        "& h6": {
            textTransform: "none",
            marginBottom: "3px"
        },
        "& .custom-input": {
            marginTop: "20px"
        }
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        justifyContent: "end !important",
        MsFlexPack: "end !important",
        WebkitBoxPack: "end !important",
        padding: "0px",
    },
    danger: {
        color: dangerColor[0],
        marginTop: "10px"
    },
    success: {
        color: successColor[0]
    },
    justifyCenter: {
        justifyContent: "center !important",
    },
    anchor: {
        color: primaryColor[0],
        cursor: "pointer",
        textAlign: "left",
        display: "inline-block"
    },
});
  
export default confirmationModalStyle;
  