import React from 'react';
import { Helmet } from 'react-helmet';
import { createBrowserHistory } from 'history';
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import Auth from "../../../assets/js/utils/Auth";
import { helper } from "../../../assets/js/utils/Element";
import PropTypes from "prop-types";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Button from "../../components/CustomButtons/Button";
import GridItem from "../../components/Grid/GridItem";
import ReactTable from "react-table";
import LoaderComponent from "../../components/Loader";
import RefreshIcon from "@material-ui/icons/Refresh";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import foldericon from "../../../assets/img/foldericon.png";
import DeleteLinkModal from "../../components/User/DeleteLinkModal";
import RestoreLinkModal from "../../components/User/RestoreLinkModal";
import Link from "@material-ui/core/Link";
import AttachmentsModal from  "../../components/User/AttachmentsModal";

import MaterialIcon from "@mdi/react";
import { mdiArrowExpandUp, mdiTrashCanOutline } from '@mdi/js';

import withStyles from "@material-ui/core/styles/withStyles";
import userTrashPageStyle from "../../../assets/jss/user/userTrashPageStyle.jsx";
const history = createBrowserHistory();

const UserTrash = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;
        let path = helper.getParam("dir");
        if(path === null || path === "null"){
            path = "/";
        }
        const backPath = this.getBackPath(path);

        this.state = {
            limit: 200,
            totalItemCount: 0,
            response: null,
            cancelToken: null,
            page: 1,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            checked: [],
            path: decodeURIComponent(path.trim()),
            backPath: backPath,
            deletelinkModal: false,
            restoreLinkModal: false,
            trash: [],
            downloadUrl: "",
            attachmentsModal: false,
            attachmentFiles: [],
            checkedAll: false,
            restoreLinkMessage: '',
            restoreLoading: false
        };

        this.loadingFolder = false;
    }
    componentDidMount(){
        const { authorized } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
            return;
        }
        if(authorized){
            this.loadLinks();
        }
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    getBackPath(path){
        if(typeof(path) !== "string" || path.trim().length <= 0){
            return "";
        }
        if(path.length <= 1 || path.trim() === "/"){
            return "";
        }
        if(path.substr(0, 1) !== "/"){
            path += "/";
        }
        path = decodeURIComponent(path.trim()); //Decode path
        path = path.replace(/^\/+|\/+$/g, ''); //Remove trailing slash
        const pathArray = path.split("/");
        pathArray.pop();
        let backPath = pathArray.join("/");
        if(backPath.length <= 0){
            backPath = "/";
        }
        return backPath;
    }
    loadLinks(viewMore = false, newPath = null, backPath = ""){
        const source = axios.CancelToken.source();
        let { page, path, trash } = this.state;
        if(viewMore){
            page += 1;
        }else{
            page = 1;
        }
        let requestData = {
            page: page,
            limit: 200,
            trash: 1
        };
        backPath = this.getBackPath(path);
        history.push("/user/trash/links?dir="+encodeURIComponent(path));
        Api.getDeletedLinks(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            data.response.map(link => {
                if(link.trash){
                    trash.push(link.id);
                }
                return null;
            });
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages,
                trash: trash
            });
            this.loadingFolder = false;
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loading: (!viewMore), 
            loadingMore: viewMore,
            showError: false, 
            cancelToken: source,
            page: page,
            path: path,
            backPath: backPath,
            checked: viewMore ? this.state.checked : [],
        });
        this.loadingFolder = true;
    }
    handleToggle(link) {
        if(this.loadingFolder){
            return;
        }
        
        const { checked } = this.state;
        const currentIndex = checked.indexOf(link.id);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
            newChecked.push(link.id);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
            checked: newChecked
        });
    }
    onAttachmentsModal(status = false, files = []){
        this.setState({
            attachmentsModal: status,
            attachmentFiles: files
        })
    }
    getAttachmentsPlainText(link){
        let attachment = "";
        if(link.files.length > 2){
            attachment = (
                link.attachments
            )
        }else {
            attachment = (
                link.files.map((file, key) => {
                    return (
                        file.name
                    );
                })
            )
        }

        return attachment;
    }
    getAttachments(link){
        let attachment = "";
        if(link.files.length > 2){
            attachment = (
                <Button simple onClick={() => this.onAttachmentsModal(true, link.files)}>{link.attachments}</Button>
            )
        }else {
            attachment = (
                link.files.map((file, key) => {
                    return (
                        <Link href={file.downloadLink} target="_blank" key={key}>
                            {file.name}
                        </Link>
                    );
                })
            )
        }

        return attachment;
    }
    getType(link){
        let label = "Direct Upload";
        if(link.upload_type.length <= 0){
            link.upload_type = link.type;
        }
        switch(link.upload_type){
            case 'web':
                label = 'Web';
                break;
            case 'my_docs':
                label = 'My Docs';
                break;
            case 'direct_upload':
                label = 'Direct Upload';
                break;
            case 'outlook':
                label = 'Outlook';
                break;
            case 'desktop':
                label = 'Desktop';
                break;
            case 'mobile':
                label = 'Mobile';
                break;
            // case 'share':
            //     label = 'Shared';
            //     break;
            // case 'manager_upload':
            //     label = 'File Manager Upload';
            //     break;
            default:
                if(link.path.indexOf("Web")){
                    label = "Web";
                }else if(link.path.indexOf("Outlook")){
                    label = "Outlook";
                }else if(link.path.indexOf("My Docs")){
                    label = "Desktop";
                }
                break;
        }
        return label;
    }
    onToggleAllFiles(e){
        if(this.loadingFolder){
            return;
        }

        const checkedFiles = [];
        if(e.target.checked){
            const { response } = this.state;

            response.map((file, key) => {
                checkedFiles.push(file.id);
                return null;
            });
        }
        
        this.setState({
            checkedAll: !this.state.checkedAll,
            checked: checkedFiles
        });
    }
    getColumns(){
        const { classes } = this.props;

        let header = (
            <Checkbox
                tabIndex={-1}
                checked={this.state.checkedAll}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                onChange={(e) => this.onToggleAllFiles(e)}
                classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                }}
            />
        );

        let columns = [
            {
                Header: header,
                accessor: "check",
                headerClassName: "hd_check",
                className: "hd_check td_check",
                sortable: false,
                filterable: false,
                resizable: false
            },
            {
                Header: "Attachment(s)",
                accessor: "attachmentsPlainText",
                headerClassName: "hd_attachments hd_show_head",
                className: "hd_attachments td_attachments td_hide",
                resizable: false
            },
            {
                Header: "Attachment(s)",
                accessor: "attachments",
                headerClassName: "hd_attachments hd_hide",
                className: "hd_attachments td_attachments",
            },
            {
                Header: "Type",
                accessor: "type",
                headerClassName: "hd_type",
                className: "hd_type td_type",
            },
            {
                Header: "Size",
                accessor: "orignalSize",
                headerClassName: "hd_size hd_show_head",
                className: "hd_size td_size td_hide",
                resizable: false
            },
            {
                Header: "Size",
                accessor: "size",
                headerClassName: "hd_size hd_hide",
                className: "hd_size td_size",
            },
            {
                Header: "Uploaded",
                accessor: "date",
                headerClassName: "hd_creation_date",
                className: "hd_creation_date td_creation_date",
                sortMethod: (a, b) => {
                    var a1 = new Date(a).getTime();
                    var b1 = new Date(b).getTime();
                  if(a1<b1)
                    return 1;
                  else if(a1>b1)
                    return -1;
                  else
                    return 0;
                }
            },
            {
                Header: "Creator",
                accessor: "owner",
                headerClassName: "hd_owner",
                className: "hd_owner td_owner",
            },
            
        ]

        return columns;
    }
    getTableData(){
        const { classes } = this.props;
        const { response, checked, backPath } = this.state;
        if(response === null){
            return [];
        }
        let tableData = [];
        if(backPath.length > 0){
            let linkArray = {
                id: 0,
                attachmentsPlainText: "",
                attachments: "",
                type: "",
                check: "",
                path: "",
                name: (
                    <Button color="transparent" justIcon className={classes.fileIcon}>
                        <img src={foldericon} alt={"folder"} />
                    </Button>
                ),
                orignalSize: "",
                size: "",
                date: "",
                owner: "",
            };
            tableData.push(linkArray);
        }
        response.map(link => {
            let linkArray = {
                id: link.id,
                attachmentsPlainText: this.getAttachmentsPlainText(link),
                attachments: this.getAttachments(link),
                type: this.getType(link),
                path: link.path,
                check: (
                    <Checkbox
                        tabIndex={-1}
                        checked={checked.includes(link.id)}
                        className={classes.positionAbsolute}
                        onClick={() => this.handleToggle(link)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                orignalSize: link.orignalSize,
                size: link.size,
                date: link.date,
                owner: link.owner,
            };
            tableData.push(linkArray);
            return null;
        });
        return tableData;
    }
    loadFolder(e, parent){
        e.preventDefault();
        this.loadLinks(false, parent.name);
    }
    getTrProps(state, rowInfo){
        if (rowInfo && rowInfo.row) {
            // const { checked } = this.state;
            return {
                onClick: (e) => this.handleToggle(rowInfo.original),
                // style: {
                //     background: checked.includes(rowInfo.original.id) ? 'rgba(0, 0, 0, 0.7)' : 'transparent',
                // }
            }
        }else{
            return {}
        }
    }
    deleteSelected(){
        const { checked } = this.state;

        if(checked.length <= 0){
            return;
        }
        this.setState({deletelinkModal: true});
    }
    onDeleteModalClose(deletelinkModal = true){
        let checked = this.state.checked;
        if(deletelinkModal === false){
            checked = [];
        }
        this.setState({
            deletelinkModal: deletelinkModal,
            checked: checked,
            checkedAll: false
        });
    }
    onDeleteModalSuccess(){
        const { checked, response } = this.state;
        const source = axios.CancelToken.source();
        const requestData = {
            links: checked,
        };
        Api.deleteLinksPermanently(requestData, source).then(data => {
            //Handle Success;
        }).catch(err => {
            //Handle Error
        });

        let newResponse = [];
        response.map(link => {
            if(!checked.includes(link.id)){
                newResponse.push(link);
            }
            return null;
        });
        this.setState({
            response: newResponse,
            checked: [],
            deletelinkModal: false,
            checkedAll: false
        });
    }
    restoreSelected(){
        const { checked } = this.state;

        if(checked.length <= 0){
            return;
        }
        this.setState({restoreLinkModal: true});
    }
    onRestoreModalClose(loadLinks = false, restoreLinkModal = true){
        let checked = this.state.checked;
        if(restoreLinkModal === false){
            checked = [];
        }
        this.setState({
            restoreLinkModal: restoreLinkModal,
            checked: checked,
            checkedAll: false,
            restoreLinkMessage: '',
            restoreLoading: false
        });

        if(loadLinks){
            this.loadLinks();
        }
    }
    onRestoreModalSuccess(){
        const { checked, response } = this.state;
        let newResponse = [];
        response.map(link => {
            if(!checked.includes(link.id)){
                newResponse.push(link);
            }
            return null;
        });
        this.setState({
            response: newResponse,
            checked: [],
            restoreLinkModal: false,
            checkedAll: false
        });
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    getBreadCrumbs(){
        let { path } = this.state;
        let breadcrumbs = [{
            value: "/",
            label: "Home"
        }];
        if(path === "/"){
            return breadcrumbs;
        }
        if(path.substr(0,1) === "/"){
            path = path.substr(1);
        }
        const pathArray = path.split("/");
        let newPath = "";
        pathArray.map(path => {
            newPath += "/"+path;
            breadcrumbs.push({
                value: newPath,
                label: path
            });
            return null;
        });
        return breadcrumbs;
    }
    getCheckedLinks(){
        const { response, checked } = this.state;
        if(checked.length <= 0){
            return [];
        }
        let links = [];
        response.map((link) => {
            if(checked.indexOf(link.id) !== -1){
                links.push(link);
            }
            return null;
        });
        return links;
    }
    render() {
        const { classes } = this.props;
        const { limit, loading, loadingMore, page, totalPages, deletelinkModal, restoreLinkModal, attachmentsModal,
            attachmentFiles, restoreLinkMessage, restoreLoading, checked } = this.state;
        const { authorized, user } = this.store.getState();
        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        const checkedLinks = this.getCheckedLinks();
        const tableData = this.getTableData();
        return (
            <div className={classes.main}>
                <div className={classes.container}>
                    <div className={classes.content}>
                        <div className={classes.controls}>
                            <GridItem>
                                <h4>Trash Links</h4>
                            </GridItem>
                            <div className={classes.controlButtons+" "+(user.theme === 'standard' ? '': 'light-theme-buttons')}>
                                <Button color="transparent" onClick={() => this.deleteSelected()}>
                                    <MaterialIcon path={mdiTrashCanOutline} className="MuiSvgIcon-root" />
                                    Delete
                                </Button>
                                <span className={classes.separator}></span>
                                <Button color="transparent" onClick={() => this.restoreSelected(true)}>
                                    <MaterialIcon path={mdiArrowExpandUp} className="MuiSvgIcon-root" />
                                    Restore
                                </Button>
                            </div>
                        </div>
                        <div className={classes.files}>
                            {
                                loading ?
                                    <LoaderComponent color="custom" align="center" />
                                :
                                null
                            }
                            <ReactTable
                                columns={this.getColumns()}
                                data={tableData}
                                pageSizeOptions={[500]}
                                defaultPageSize={limit}
                                showPaginationTop={false}
                                minRows={0}
                                showPaginationBottom={false}
                                className={"-striped -highlight "+ (loading ? classes.loading : "")+" "+classes.filesTable}
                                getTrProps={(state, rowInfo) => this.getTrProps(state, rowInfo)}
                                getNoDataProps={() => this.getNoDataProps()}
                            />
                        </div>
                        <div className={classes.loadMore}>
                            {
                                tableData.length > 0 && loadingMore === false && page < totalPages ?
                                    <Button color="custom" id="contentViewMore" round onClick={(e) => this.loadLinks(true)}>
                                        <RefreshIcon className={classes.icons} />View More
                                    </Button>
                                    
                                :
                                    loadingMore === true ?
                                        <LoaderComponent color="custom" align="center" />
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </div>
                {
                    deletelinkModal ?
                        <DeleteLinkModal deletedLinks={checkedLinks} open={deletelinkModal} onClose={() => this.onDeleteModalClose(false)} onSuccess={() => this.onDeleteModalSuccess()} />
                    :
                        <></>
                }
                {
                    restoreLinkModal ?
                        <RestoreLinkModal store={this.store} restoreLinks={checked} restoreLoading={restoreLoading} errorMessage={restoreLinkMessage ? restoreLinkMessage : ''} open={restoreLinkModal} onClose={(loadLinks) => this.onRestoreModalClose(loadLinks, false)} onSuccess={() => this.onRestoreModalSuccess()} />
                    :
                        <></>
                }
                {
                    attachmentsModal ?
                        <AttachmentsModal 
                            open={attachmentsModal} 
                            onClose={() => this.onAttachmentsModal(false)}
                            attachmentFiles={attachmentFiles}
                        />
                    :
                        <></>
                }
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

UserTrash.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userTrashPageStyle)(UserTrash);
